// an alert component with title, message, action buttons, and close button

import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@yonderlabs-packages/ui';

import { Button, LinkButton, type ButtonProps, type LinkButtonProps } from '../button';

const alertVariants = cva(
  'px-8 py-6 flex flex-col flex-wrap gap-6 items-start justify-start rounded transition-colors focus:outline-none focus:ring-2 focus:ring-yonder-400 focus:ring-offset-2 lg:flex-row lg:justify-between lg:items-center',
  {
    variants: {
      variant: {
        default: 'bg-yonder-main',
        success: 'bg-green-50 text-green-700',
        warning: 'bg-amber-500/10 text-yellow-900 border-amber-500 border-2',
        danger: 'bg-red-50 text-red-700',
        info: 'border-2 border-yonder-50/50 text-yonder-500',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

// interface AlertLinkButtonProps extends LinkButtonProps {
//   as: 'link';
// }

// interface AlertButtonProps extends ButtonProps {
//   as: 'button';

interface AlertProps extends React.HtmlHTMLAttributes<HTMLDivElement>, VariantProps<typeof alertVariants> {
  heading?: string | React.ReactNode;
  message?: string | React.ReactNode;
  actions?: (LinkButtonProps | ButtonProps)[];
}

// TODO: move this to a shared location there is a duplicate in the NextStepsCard.tsx file
const isLinkButton = (action: LinkButtonProps | ButtonProps): action is LinkButtonProps => {
  return (action as LinkButtonProps).to !== undefined;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  ({ className, variant, message, heading, actions, ...props }, ref) => {
    return (
      <div className={cn(alertVariants({ variant, className }))} ref={ref} {...props}>
        <div className={`flex flex-1 flex-col flex-wrap gap-1 ${actions && actions.length > 0 ? '' : null}`}>
          {heading && <h3 className='text-base font-medium leading-6'>{heading}</h3>}
          {message && <p className='text-sm font-normal leading-5'>{message}</p>}
        </div>

        {actions && actions.length > 0 ? (
          <div className='flex flex-wrap gap-4'>
            {actions.map((action, index) =>
              isLinkButton(action) ? <LinkButton key={index} {...action} /> : <Button key={index} {...action} />,
            )}
          </div>
        ) : null}
      </div>
    );
  },
);

Alert.displayName = 'Alert';

Alert.defaultProps = {
  title: undefined,
  message: undefined,
  actions: [],
};

export { Alert, alertVariants };
